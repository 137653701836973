<template>
    <main class="px-2 md:px-4 py-6 flex flex-col gap-10">
      <h4
        class="capitalize md:uppercase md:text-sm md:text-left text-xs font-medium text-headerText"
      >
        Book Keeping / Invoice / View {{ isEdit && "/ Edit" }}
      </h4>
  
      <ViewInvoice  />
    </main>
  </template>
  
  <script setup>
  import ViewInvoice from "@/components/BookKeeping/Invoice/ViewInvoice";
  
  import { computed, ref } from "vue";
  import { useRoute } from "vue-router";
  import { helperFunctions } from "@/composable/helperFunctions";
  import { useDataStore } from "@/stores/data.js";
  
  const { formatAmount } = helperFunctions;
  
  const store = useDataStore();
  const route = useRoute();
  
  const isEdit = computed(() => route.query.edit || null);
  const inventoryId = computed(() => route.params.id || null);
  </script>
  
  <style></style>
  